import {StyleSheet, Dimensions} from 'react-native';
import {themeStyle} from '../../../styles';
const {width, height} = Dimensions.get('window')
const maxHeight = height * 0.85 * 0.4

export const dropdownStyles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currencyDropdownContainer: {
    paddingVertical: 15,
    width: 150,
  },
  content: {
    width: '100%',
    maxHeight: maxHeight,
    padding: themeStyle.spacing.SPACING_25,
    backgroundColor: themeStyle.colors.WHITE,
    borderRadius: themeStyle.spacing.SPACING_5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    paddingBottom: themeStyle.spacing.SPACING_20,
    textAlign: 'center',
  },
  message: {
    paddingBottom: themeStyle.spacing.SPACING_20,
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_MEDIUM,
    color: themeStyle.colors.GRAY40,
    textAlign: 'center',
  },
  currency: {
    fontFamily: themeStyle.fonts.BOLD,
    fontSize: 40,
    color: themeStyle.colors.WHITE,
},
});

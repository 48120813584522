import {StyleSheet} from 'react-native';
import themeStyle from './theme.style';

const commonStyle = StyleSheet.create({
  app: {
    flex: 1,
    justifyContent: 'center',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  scrollViewContainer: {
    paddingVertical: themeStyle.spacing.SPACING_20,
    alignContent: 'center',
  },
  whiteBg: {
    backgroundColor: themeStyle.colors.WHITE,
  },
  lightText: {
    color: themeStyle.colors.LIGHT_GRAY,
    fontFamily: themeStyle.fonts.SEMIBOLD,
  },
  mediumLightText: {
    color: themeStyle.colors.MEDIUM_GRAY,
    fontFamily: themeStyle.fonts.SEMIBOLD,
  },
  darkText: {
    color: themeStyle.colors.BLACK_LIGHT,
    fontFamily: themeStyle.fonts.SEMIBOLD,
  },
  primaryText: {
    color: themeStyle.colors.PRIMARY,
  },
  greenText: {
    color: themeStyle.colors.GREEN,
  },
  meadowText: {
    color: themeStyle.colors.MEADOW,
  },
  xlargeText: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_XLARGE,
    fontWeight: themeStyle.fontWeight.BOLD,
    fontFamily: themeStyle.fonts.BOLD,
  },
  largeText: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    fontWeight: themeStyle.fontWeight.BOLD,
    fontFamily: themeStyle.fonts.BOLD,
  },
  mediumText: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_MEDIUM,
    fontFamily: themeStyle.fonts.REGULAR,
  },
  smallText: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_SMALL,
  },
  imageSize: {
    width: 300,
    height: 200,
  },
  iconSize: {
    width: 32,
    height: 32,
  },
  left: {
    marginLeft: themeStyle.spacing.SPACING_15,
  },
  top: {
    marginTop: themeStyle.spacing.SPACING_25,
  },
  bottom: {
    marginBottom: themeStyle.spacing.SPACING_25,
  },
  bottom20: {
    marginBottom: 20,
  },
  topLeft: {
    marginTop: themeStyle.spacing.SPACING_20,
    marginLeft: themeStyle.spacing.SPACING_20,
  },
  topLeftAbsolute: {
    position: 'absolute',
    top: 40,
    left: themeStyle.spacing.SPACING_20,
  },
  alignToBottom: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paddingBetween: {
    paddingLeft: themeStyle.spacing.SPACING_25,
    paddingRight: themeStyle.spacing.SPACING_25,
  },
  paddingUpDown: {
    paddingTop: themeStyle.spacing.SPACING_15,
    paddingBottom: themeStyle.spacing.SPACING_15,
  },
  borderBottom: {
    borderBottomWidth: themeStyle.borders.WIDTH,
    borderBottomColor: themeStyle.colors.LIGHT_GRAY_50,
  },
  topSpacing: {
    marginTop: 50,
  },
  normalWeight: {
    fontWeight: 'normal',
  },
});

export default commonStyle;

import React from 'react';
import {View, ImageBackground} from 'react-native';
import {Text} from '../index';
import {componentStyle} from '../../styles';
import PropTypes from 'prop-types';
import Navbar from './Navbar';

export const Header = ({
  title,
  logo,
  onBackPress,
  onExtendPress,
  imageType,
  step,
}) => {
  return (
    <View style={componentStyle.header}>
      <Navbar
        onBackPress={onBackPress}
        onExtendPress={onExtendPress}
        logo={logo}
      />
      {imageType && (
        <ImageBackground
          style={componentStyle.headerImageBackground}
          source={imageType}>
          <View style={componentStyle.headerImageBackgroundWrapper}>
            <Text style={componentStyle.textHeader}>{title}</Text>
            {step && (
              <Text style={componentStyle.textStepIndicator}>
                {step}
              </Text>
            )}
          </View>
        </ImageBackground>
      )}
    </View>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

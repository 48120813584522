// Home.js, just a name of the stack navigation

import {createStackNavigator} from 'react-navigation-stack';
import {createAppContainer} from 'react-navigation';
import {themeStyle} from '../styles'
import Login from "../screens/Login";
import Landing from "../screens/Landing";
import Payment from '../screens/Payment';


const Home = createStackNavigator(
    {

        Login,
        Landing,
        Payment,
    },
    {
        headerMode: 'none',
        navigationOptions: {
            headerVisible: false
        },
    }
);


const container = createAppContainer(Home);

export default container;
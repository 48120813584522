import env from 'react-native-config';

export const APP_SUFFIX = env.APP_SUFFIX || '';
//DEV
// export const API_URL = 'http://office.clicid.com:5000';
// export const API_KEY = 'testkey';
//TEST
// export const API_URL = 'https://test-app.clicid.com';
// export const API_KEY = 'd9d4eede-6277-4ae4-a864-1a827d0894ee';
//PROD
export const API_URL = 'https://api.clicid.com';
export const API_KEY = '5d465df0-06f8-47b6-88b0-82541e656fc2';
export default {
  APP_SUFFIX: 'demo',
  API_URL: 'https://api.clicid.com',
};
import {StyleSheet} from 'react-native';
import {themeStyle} from '../../styles';

const checkBoxStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: themeStyle.spacing.SPACING_20,
    marginBottom: themeStyle.spacing.SPACING_20,
  },
  wrapper: {
    borderWidth: 1,
    borderColor: themeStyle.colors.PRIMARY,
    borderRadius: themeStyle.borders.RADIUS_3,
    marginRight: themeStyle.spacing.SPACING_10,
  },
  text: {
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: themeStyle.fonts.BOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XSMALL,
    color: themeStyle.colors.BLACK,
  },
});

export default checkBoxStyles;

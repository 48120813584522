export const recipients = [
    {
        name: 'Tractors and Supply',
        idNumber: '4327-5892-1224',
    },
    {
        name: 'VAT payment account',
        idNumber: '3839-6929-1048',
    },
    {
        name: 'VAT',
        idNumber: '0204-9184-4838',
    },
    {
        name: 'Rent',
        idNumber: '9372-8105-0275',
    },
]
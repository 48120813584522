export const accounts = [
    {
        type: 'Savings Account',
        name: '\'s savings account',
        idNumber: '4552-5224-3162',
        currency: 'AED',
        balance: '1,911.58'
    },
    {
        type: 'Checkings Account',
        name: '\'s checkings account',
        idNumber: '4112-7261-9205',
        currency: 'AED',
        balance: '18,192.00'
    },
    {
        type: 'Savings Account',
        name: '\'s secret account',
        idNumber: '9386-7482-0924',
        currency: 'AED',
        balance: '8,389.00'
    },
]
import {StyleSheet} from 'react-native';
import themeStyle from './theme.style';

const screenStyle = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  contentContainer: {
    alignContent: 'center',
  },
  inputsContainer: {
    marginTop: themeStyle.spacing.SPACING_20,
  },
  labelsContainer: {
    color: themeStyle.colors.BLACK_LIGHT,
    fontSize: themeStyle.fontSizes.FONT_SIZE_MEDIUM,
    paddingBottom: themeStyle.spacing.SPACING_15,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  // Localization:
  localizationContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  // Terms agreement
  termsAgreementText: {
    marginTop: themeStyle.spacing.SPACING_20,
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XSMALL,
    color: themeStyle.colors.DARK_GRAY,
    textAlign: 'left',
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  scrollViewContentContainerV2: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  // PIN Code
  pinCodeContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: themeStyle.spacing.SPACING_20,
    padding: themeStyle.spacing.SPACING_20,
  },
  pinCodeHeader: {
    zIndex: 1,
    width: '100%',
    paddingVertical: themeStyle.spacing.SPACING_10,
    elevation: 5,
    alignItems: 'center',
  },
  pinContainer: {
    marginBottom: themeStyle.spacing.SPACING_20,
    alignItems: 'center',
  },
  loginHeader: {
    zIndex: 1,
    paddingTop: themeStyle.spacing.SPACING_25,
    paddingBottom: themeStyle.spacing.SPACING_25,
    paddingLeft: themeStyle.spacing.SPACING_10,
    paddingRight: themeStyle.spacing.SPACING_10,
    alignItems: 'center',
  },
  fingerprintContainer: {
    marginTop: themeStyle.spacing.SPACING_25,
    marginBottom: themeStyle.spacing.SPACING_25,
    width: 100,
    height: 100,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Document Info, VerificationInfo
  infoTitle: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    color: themeStyle.colors.BLACK_LIGHT,
  },
  // DocumentType
  checkBoxContainer: {
    backgroundColor: themeStyle.colors.WHITE,
    borderWidth: 0,
  },
  checkBoxWrapper: {
    backgroundColor: themeStyle.colors.WHITE,
    borderWidth: 0,
  },
  checkList: {
    borderWidth: themeStyle.borders.WIDTH,
    borderColor: themeStyle.colors.LIGHT_GRAY_50,
    backgroundColor: themeStyle.colors.WHITE,
    borderRadius: themeStyle.borders.RADIUS_5,
    marginTop: -themeStyle.spacing.SPACING_10,
  },
  // UserProfile
  profilePhoto: {
    height: 72,
    width: 72,
    borderRadius: 36,
  },
  profilePictureContainer: {
    width: 72,
    height: 72,
    borderRadius: 36,
    backgroundColor: themeStyle.colors.WHITE_SMOKE,
    marginBottom: themeStyle.spacing.SPACING_10,
  },
  profilePictureContainerLabel: {
    textAlign: 'center',
    lineHeight: 72,
    color: themeStyle.colors.BLACK_LIGHT,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XLARGE,
  },
  personalDataContainer: {
    borderBottomColor: themeStyle.colors.BORDER_COLOR,
    borderBottomWidth: 1,
    paddingHorizontal: themeStyle.spacing.SPACING_25,
    paddingVertical: themeStyle.spacing.SPACING_25,
  },
  fingerprintOption: {
    position: 'absolute',
    right: 5,
    top: -4,
  },
});

export default screenStyle;

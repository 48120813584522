import {StyleSheet} from 'react-native';
import {themeStyle} from '../../../styles';

export const modalDialogStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: themeStyle.spacing.SPACING_25,
    backgroundColor: themeStyle.colors.WHITE,
    borderRadius: themeStyle.spacing.SPACING_5,
  },
  title: {
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    paddingBottom: themeStyle.spacing.SPACING_20,
  },
  message: {
    paddingBottom: themeStyle.spacing.SPACING_20,
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_MEDIUM,
    color: themeStyle.colors.GRAY40,
  },
});

import React from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
import Home from './navigation/Home'
import {useFonts} from 'expo-font'
const {width, height} = Dimensions.get('window');

export default function App() {
  let [fontsLoaded] = useFonts({
    'NunitoSans-Bold': require('./assets/fonts/NunitoSans-Black.ttf'),
    'NunitoSans-Regular': require('./assets/fonts/NunitoSans-Regular.ttf'),
    'NunitoSans-SemiBold': require('./assets/fonts/NunitoSans-SemiBold.ttf'),
  });
  if (!fontsLoaded) {
    return <></>
  }
  return (
    <View style={styles.outer}>
        <Home />
    </View>
  );
}

const styles = StyleSheet.create({
  outer: {
    flex: 1,
  },
  container: {
    height: height,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

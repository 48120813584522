import React from 'react';
import {View, Text} from 'react-native';
import Modal from 'react-native-modal';
import {waitingForClicIdStyles} from './style';
import {Button} from '../../index';

export const WaitingForClicId = ({
  title,
  message,
  buttonTitle,
  onPress,
  isVisible,
  text,
}) => {
  return (
    <View style={waitingForClicIdStyles.container}>
      <View style={waitingForClicIdStyles.content}>
        <Text style={waitingForClicIdStyles.title}>{'Waiting for clicID'}</Text>
        <Text style={waitingForClicIdStyles.message}>{text || 'Please open clicID on your device to complete the verification.'}</Text>
      </View>
    </View>
  );
};

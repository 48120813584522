import axios from 'axios'
import {API_URL, API_KEY} from '../config';
import {config} from '../constants';

export const axiosInstance = axios.create();

const callApi = async (method, path, options) => {
  const requestURL = `${API_URL}${path}`
  const requestConfig = {
    baseURL: API_URL,
    url: path,
    method,
    params: options && options.queryParams,
    data: options && options.body,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': API_KEY,
      ...(options && options.headers),
    },
    responseType:
      options && options.responseType ? options.responseType : 'json',
    timeout: config.DEFAULT_TIMEOUT,
  };
  return axiosInstance(requestConfig);
};

export default class ApiClient {
  static get = (path, options) => {
    return callApi('get', path, options);
  };

  static post = (path, options) => {
    return callApi('post', path, options);
  };

  static put = (path, options) => {
    return callApi('put', path, options);
  };

  static delete = (path, options) => {
    return callApi('delete', path, options);
  };
}

import React from 'react';
import {TouchableOpacity, Text, View} from 'react-native';
import { themeStyle } from '../../styles';
import buttonStyles from './styles';

export const Button = ({onPress, title, enabled, wide, style, lightTheme}) => {
  return (
    <View
      style={[
        wide ? buttonStyles.wideButtonContainer : buttonStyles.buttonContainer,
        style
      ]}>
      <TouchableOpacity
        style={[
          buttonStyles.button,
          enabled ? buttonStyles.buttonActive : buttonStyles.buttonInactive,
          lightTheme && {backgroundColor: 'transparent', borderColor: themeStyle.colors.NAVY, borderWidth: 2}
        ]}
        onPress={onPress}
        disabled={!enabled}>
        <Text style={[
          buttonStyles.buttonText,
          lightTheme && {color: themeStyle.colors.NAVY},
        ]}>{title}</Text>
      </TouchableOpacity>
    </View>
  );
};

// Login Screen

import React from 'react';
import {View, StyleSheet, Platform, Image} from 'react-native';
import {commonStyle, themeStyle} from '../styles'
import {httpStatus} from '../constants'
import {Button, Label, Text, TextInput, WaitingForClicId} from '../components';
import ApiClient from '../api/client'
const clicIDLogin = require('../assets/images/clic-id-login.png')
const regularLogin = require('../assets/images/regular-login.png')

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicIdNumber: '',
            polling: false,
            signingError: null,
        }
    }

    componentDidMount() {

    }

    login = async (data) => {
        //TODO: send id to next screen
        //TODO: Verification request to clicID app for login?
        const id = this.state.clicIdNumber
        await this.setState({clicIdNumber: ''})
        this.props.navigation.push('Landing', {clicId: id, userData: data, fromLogin: true})
    }

    loginVerification = async () => {
        const id = this.state.clicIdNumber
        try {
            const response = await ApiClient.post(
                `/service-provider/1.0/verification`,
                {
                    body: {
                        "clicId": id,
                        "userIpAddress": "string",
                        "visibleText": "Login Demo Bank",
                        "invisibleText": "string",
                        "backUrl": "string",
                        "technicalReference": "string"
                    }
                }
            )
            if (response && response.status === httpStatus.OK && response.data) {
                const verificationId = response.data.verificationId
                this.startPolling(verificationId)
            }
        } catch(e) {
            this.setState({signingError: 'Invalid clicID number, please try again'})
        }
    }

    startPolling = (verificationId) => {
        this.setState({polling: true, signingError: null})
        const pollingInterval = setInterval(() => poll(), 1000)
        const poll = async () => {
            try {
                const response = await ApiClient.get(
                  `/service-provider/1.0/verification/${verificationId}`
                );
                if (response && response.status === httpStatus.OK && response.data) {
                    const status = response.data.status
                    if (status !== 'pending') {
                        stopPolling();
                        switch (status) {
                            case 'success':
                                const data = response.data.successData
                                this.login(data)
                                break;
                            case 'usercancel':
                                this.setState({signingError: 'The request was canceled, try again.'})
                                break;
                            case 'timeout':
                                this.setState({signingError: 'The request timed out, try again.'})
                                break;
                            default:
                                this.setState({signingError: 'Something went wrong, try again.'})
                                break;
                        }
                    }
                }
              } catch (e) {

              }
        }
        const stopPolling = () => {
            clearInterval(pollingInterval)
            this.setState({polling: false})
        }
    }



    render() {
        const {polling, signingError, clicIdNumber} = this.state
        return( 
            <View style={styles.container}>
                <View style={styles.logoContainer}>
                    <Image source={require('../assets/images/demo_bank_logo.png')} resizeMode={'contain'} style={{width: 170, height: 44, alignSelf: 'center', tintColor: '#fff'}} />
                </View>
                <View style={styles.content}>
                {polling ? 
                    <WaitingForClicId text={'Please open clicID on your device to complete the login.'}/>
                    :<>
                    <Text style={[commonStyle.xlargeText, commonStyle.paddingBetween, {textAlign: 'center'}]}>Welcome to Demo Bank</Text>
                    <Text style={[commonStyle.largeText, commonStyle.paddingUpDown, commonStyle.bottom, commonStyle.paddingBetween, {textAlign: 'center'}]}>Please select your login method</Text>
                    <View style={[styles.loginMethods, commonStyle.paddingUpDown, commonStyle.paddingBetween, commonStyle.bottom]}>
                        <View style={[commonStyle.bottom, styles.loginMethod]}>
                            <Image source={clicIDLogin} style={{height: 105, width: 105}}/>
                            <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown]}>clicID</Text>
                        </View>
                        <View style={[commonStyle.bottom, styles.loginMethod]}>
                            <Image source={regularLogin} style={{height: 105, width: 105}}/>
                            <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown]}>Regular</Text>
                        </View>
                    </View>
                    <View style={[styles.inputContainer, commonStyle.paddingBetween]}>
                        <Label name={'Your clicID no.'}/>
                        <TextInput label={'YYMMDD-XXXXA'} name={'clicIdNumber'} onChangeText={(text) => {
                            this.setState({clicIdNumber: text})
                        }} />
                        {signingError && <View>
                        <Text style={styles.signError}>{signingError}</Text>
                        </View>}
                    </View>
                    <Button
                        title="Login"
                        style={{width: 342, alignSelf: 'center'}}
                        onPress={() => this.loginVerification()}
                        enabled={clicIdNumber?.length == 12}
                    />
                    </>}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: themeStyle.colors.NAVY,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logoContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60,
        padding: 10,
    },
    content: {
        height: '85%',
        width: '100%',
        backgroundColor: '#f4f4f4',        
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputContainer: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    loginMethods: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    loginMethod: {
        width: 105,
        height: 105,
        marginHorizontal: 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    signError: {
        fontFamily: themeStyle.fonts.SEMIBOLD,
        fontSize: 16,
        color: themeStyle.colors.RED,
        width: '100%',
        textAlign: 'center',
    },    
})
import {StyleSheet, Dimensions} from 'react-native';
import themeStyle from './theme.style';

const height = Dimensions.get('window').height;
const topOffset = -height * 2 * 0.83;
const elevation = 12;

const componentStyle = StyleSheet.create({
  // Rounded Header
  roundedHeader: {
    marginBottom: themeStyle.spacing.SPACING_20,
    backgroundColor: themeStyle.colors.PRIMARY,
    width: height * 2,
    height: height * 2,
    position: 'absolute',
    top: topOffset,
    alignSelf: 'center',
    borderRadius: height,
    elevation: elevation,
  },
  roundedHeaderContainer: {
    width: '100%',
    height: height * 2 + topOffset,
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundedHeaderContentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: elevation,
  },
  headerLogo: {
    width: 90,
    height: 108,
  },
  idHeaderText: {
    marginTop: 10,
    color: themeStyle.colors.PRIMARY,
    fontSize: 24,
    fontWeight: '700',
    textAlign: 'center',
  },
  roundedHeaderText: {
    marginTop: 10,
    color: themeStyle.colors.WHITE,
    fontSize: 20,
    paddingHorizontal: 20,
    textAlign: 'center',
    fontWeight: '600',
  },
  roundedHeaderInfoText: {
    marginTop: 4,
    color: themeStyle.colors.WHITE,
    fontSize: 16,
    textAlign: 'center',
  },
  // Header
  header: {
    marginBottom: themeStyle.spacing.SPACING_20,
  },
  headerImageBackgroundWrapper: {
    backgroundColor: themeStyle.colors.PRIMARY_64,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerImageBackground: {
    height: 150,
    width: '100%',
    resizeMode: 'cover',
  },
  headerIcons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 55,
    padding: themeStyle.spacing.SPACING_10,
    backgroundColor: themeStyle.colors.WHITE,
  },
  textHeader: {
    position: 'absolute',
    zIndex: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    color: themeStyle.colors.WHITE,
    fontFamily: themeStyle.fonts.SEMIBOLD,
    paddingHorizontal: themeStyle.spacing.SPACING_20,
  },
  textStepIndicator: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    bottom: 2,
    fontSize: themeStyle.fontSizes.FONT_SIZE_SMALL,
    color: themeStyle.colors.WHITE,
    fontFamily: themeStyle.fonts.REGULAR,
    paddingHorizontal: themeStyle.spacing.SPACING_20,
  },

  // Logo
  logoContainer: {
    marginTop: themeStyle.spacing.SPACING_20,
    marginBottom: themeStyle.spacing.SPACING_20,
  },
  // Text
  textComponentTitle: {
    fontSize: themeStyle.fontSizes.FONT_SIZE_LARGE,
    textAlign: 'center',
    fontFamily: themeStyle.fonts.BOLD,
    fontWeight: themeStyle.fontWeight.BOLD,
    color: themeStyle.colors.BLACK,
    paddingVertical: themeStyle.spacing.SPACING_10,
  },
  textComponent: {
    textAlign: 'center',
    fontFamily: themeStyle.fonts.REGULAR,
    fontSize: themeStyle.fontSizes.FONT_SIZE_MEDIUM,
    color: themeStyle.colors.BLACK_LIGHT,
    paddingVertical: themeStyle.spacing.SPACING_10,
  },
  textComponentSmall: {
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XXSMALL,
    color: themeStyle.colors.BLACK_LIGHT,
  },
  nextStep: {
    alignSelf: 'center',
    fontFamily: themeStyle.fonts.REGULAR,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XSMALL,
    color: themeStyle.colors.GREEN,
    paddingVertical: themeStyle.spacing.SPACING_10,
  },
  // No Connectivity Notice
  offlineContainer: {
    backgroundColor: themeStyle.colors.RED,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    flexDirection: 'row',
  },
  offlineText: {
    color: themeStyle.colors.WHITE,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XSMALL,
    fontFamily: themeStyle.fonts.REGULAR,
  },
  offlineWrapper: {
    backgroundColor: themeStyle.colors.WHITE,
  },
  // Verification requests
  verificationRequestStatusBadge: {
    paddingHorizontal: themeStyle.spacing.SPACING_10,
    borderRadius: themeStyle.borders.RADIUS_7,
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    position: 'absolute',
    right: 20,
    top: -10,
    zIndex: 1,
  },
  verificationRequestStatus: {
    color: themeStyle.colors.WHITE,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XXSMALL,
    fontFamily: themeStyle.fonts.REGULAR,
  },
  verificationRequestWrapper: {
    borderWidth: themeStyle.borders.WIDTH,
    borderColor: themeStyle.colors.BORDER_COLOR,
    borderRadius: themeStyle.borders.RADIUS_10,
    padding: themeStyle.spacing.SPACING_25,
    marginBottom: themeStyle.spacing.SPACING_25,
  },
  verificationRequestLabelView: {
    borderBottomWidth: themeStyle.borders.WIDTH,
    borderBottomColor: themeStyle.colors.BORDER_COLOR,
    paddingHorizontal: themeStyle.spacing.SPACING_10,
    paddingBottom: themeStyle.spacing.SPACING_10,
    marginBottom: themeStyle.spacing.SPACING_10,
  },
  verificationRequestTimestampView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: themeStyle.spacing.SPACING_10,
  },
  verificationRequestTimestamp: {
    borderBottomWidth: themeStyle.borders.WIDTH,
    borderBottomColor: themeStyle.colors.BORDER_COLOR,
    paddingHorizontal: themeStyle.spacing.SPACING_10,
    paddingBottom: themeStyle.spacing.SPACING_10,
    marginBottom: themeStyle.spacing.SPACING_10,
  },
  // checkbox
  checkIcon: {
    padding: 2,
  },
});

export default componentStyle;

import React, {useRef, useState} from 'react';
import {
  TextInput as TextInputComponent,
  View,
  TouchableOpacity,
} from 'react-native';
import textInputStyles from './styles';
import {Label} from '../../index';
import {commonStyle} from '../../../styles';

TextInputComponent.defaultProps = {
  textAlign: 'left',
  autoCorrect: false,
  autoCapitalize: 'none',
  returnKeyType: 'next',
};

export const TextInput = ({
  label,
  errorHighlight,
  errorMsg,
  value,
  onChangeText,
  name,
  rightIcon,
  ...props
}) => {
  const inputRef = useRef(null);
  const [focused, setFocused] = useState(false);
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => inputRef.current.focus()}
      style={[
        textInputStyles.textInputWrapper,
        focused && textInputStyles.focusedHighlight,
        errorHighlight && textInputStyles.errorHighlight,
      ]}>
      {(focused || value !== '' || value !== null) && (
        <Label
          name={errorMsg !== '' ? errorMsg : label}
          style={[
            textInputStyles.textInputLabel,
            focused && textInputStyles.textInputLabelFocused,
            errorHighlight && textInputStyles.errorLabel,
          ]}
        />
      )}
      <View style={textInputStyles.textInputContainer}>
        <TextInputComponent
          ref={inputRef}
          style={[
            textInputStyles.textInputStyle,
            (focused || value !== '' || value !== null) &&
              textInputStyles.textInputFocused,
          ]}
          value={value}
          onChangeText={onChangeText}
          underlineColorAndroid={'transparent'}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={!errorHighlight ? label : ''}
          {...props}
        />
        {rightIcon}
      </View>
    </TouchableOpacity>
  );
};

// Payment screen

import React from 'react';
import {View, Text, StyleSheet, Platform, TouchableOpacity, Image} from 'react-native';
import {Button} from '../components'
import {commonStyle, themeStyle} from '../styles'

export default class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            clicIdNumber: '',
            user: {},
            fromLogin: false,
            lastPayment: null,
        }
    }
    componentDidMount() {
        const id = this.props.navigation.getParam('clicId', '')
        const userData = this.props.navigation.getParam('userData', {})
        this.setState({clicIdNumber: id, user: userData})
    }

    componentDidUpdate() {
        const fromLogin = this.props.navigation.getParam('fromLogin', false)
        const lastPayment = this.props.navigation.getParam('lastPayment', null)
        if (this.state.fromLogin !== fromLogin || lastPayment !== this.state.lastPayment) {
            this.setState({fromLogin, lastPayment})
        }
    }

    makePayment = () => {
        const {clicIdNumber, user} = this.state
        this.props.navigation.navigate('Payment', {clicId: clicIdNumber, userData: user})
    }

    render() {
        const {user, fromLogin, lastPayment} = this.state
        return (
            <View style={styles.container}>
                <View style={styles.logoContainer}>
                    <Image source={require('../assets/images/demo_bank_logo.png')} resizeMode={'contain'} style={{width: 170, height: 44, alignSelf: 'center', tintColor: '#fff'}} />
                </View>
                <View style={[styles.content, commonStyle.paddingUpDown, commonStyle.paddingBetween]}>
                    <Button
                        title="Logout"
                        onPress={() => this.props.navigation.navigate('Login')}
                        enabled
                        style={styles.logoutButton}
                        lightTheme
                    />
                    <View style={[styles.titleContainer, commonStyle.bottom]}>
                        <Text style={[styles.title]}>{fromLogin ? `Welcome ${user.name}` : `Bank dashboard\n${user.name}`}</Text>
                    </View>
                    <View style={[styles.accountInfoContainer, commonStyle.bottom]}>
                        <View style={styles.upcomingTransactionsContainer}>
                            <Text style={[commonStyle.mediumText, commonStyle.bottom, {color: themeStyle.colors.WHITE, fontFamily: themeStyle.fonts.SEMIBOLD, fontSize: 24, textAlign:'right', paddingLeft: 50}]}>
                                Upcoming transactions:
                            </Text>
                            <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown, {color: themeStyle.colors.WHITE, fontFamily: themeStyle.fonts.BOLD, fontSize: 24, textAlign:'right'}]}>
                                <Text style={{fontFamily: themeStyle.fonts.SEMIBOLD}}>AED </Text>
                                1,739.95
                            </Text>
                        </View>
                        <View style={styles.balanceContainer}>
                            <Text style={styles.balance}>
                                <Text style={{fontFamily: themeStyle.fonts.SEMIBOLD}}>AED </Text>
                                12,341.48
                            </Text>
                            <Text style={[commonStyle.largeText, commonStyle.bottom, {color: themeStyle.colors.WHITE, fontFamily: themeStyle.fonts.SEMIBOLD, textAlign:'right', marginLeft: 224}]}>
                                Available balance
                            </Text>
                        </View>
                        {lastPayment && 
                            <View style={{flex: 1, width: '23%', alignItems:'flex-start', justifyContent: 'flex-start', padding: 15, paddingTop: 27}}>
                                <Text style={[commonStyle.mediumText, commonStyle.bottom, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, fontSize: 24, textAlign:'left'}]}>
                                    Last transaction:
                                </Text>
                                <Text style={[commonStyle.mediumText, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.BOLD, fontSize: 24, textAlign:'left'}]}>
                                <Text style={{fontFamily: themeStyle.fonts.SEMIBOLD}}>{lastPayment.currency} </Text>
                                    {lastPayment.amount}
                                </Text>
                                <Text style={{fontSize: 16, color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD}}>to: </Text>
                                <Text style={{fontSize: 20, color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, textAlign:'left'}}>{lastPayment.recipient}</Text>
                            </View>}
                    </View>
                    <View style={[styles.actionButtonsContainer]}>
                        <TouchableOpacity style={[styles.actionButton, {backgroundColor: '#FFC72C'}]}
                            onPress={() => this.makePayment()}>
                            <Text style={styles.actionButtonText}>Make Payment</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.actionButton, {backgroundColor: '#FFC72C'}]}
                            onPress={() => this.makePayment()}>
                            <Text style={styles.actionButtonText}>Make Transfer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.actionButton, {backgroundColor: '#FFF'}]}>
                            <Text style={[styles.actionButtonText, {color: themeStyle.colors.BLACK_LIGHT}]}>View Details</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.actionButton, {backgroundColor: '#FFF'}]}>
                            <Text style={[styles.actionButtonText, {color: themeStyle.colors.BLACK_LIGHT}]}>Pay Card</Text>
                        </TouchableOpacity>
                    </View>
                    
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: themeStyle.colors.NAVY,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logoContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60,
        padding: 10,
    },
    content: {
        height: '85%',
        width: '100%',
        backgroundColor: '#f4f4f4',        
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logoutButton: {
        position: 'absolute',
        top: 7,
        right: 15,
        width: 150,
    },
    titleContainer: {
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: themeStyle.fonts.BOLD,
        fontSize: 40,
        color: themeStyle.colors.BLACK_LIGHT,
        textAlign: 'center',
    },
    accountInfoContainer: {
        flexDirection: 'row',
        width: '80%',
        height: '45%',
        backgroundColor: '#fff',
        alignSelf: 'center',
    },
    upcomingTransactionsContainer: {
        width:'17%',
        height: '100%',
        backgroundColor: '#FFC72C',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        paddingVertical: 27,
        paddingRight: 15,
    },
    balanceContainer: {
        width: '60%',
        height: '100%',
        backgroundColor: '#64BC78',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingVertical: 27,
        paddingRight: 20,
        paddingLeft: 120,
    },
    balance: {
        fontFamily: themeStyle.fonts.BOLD,
        fontSize: 44,
        color: themeStyle.colors.WHITE,
    },
    actionButtonsContainer: {
        flexDirection: 'row',
        height: 50,
        marginTop: 25,
        width: '80%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    actionButton: {
        height: '100%', 
        alignItems: 'center',
        justifyContent: 'center',
        width: '18%',
        maxWidth: 250,
        borderRadius: 5,

    },
    actionButtonText :{
        color: themeStyle.colors.WHITE, 
        fontFamily: themeStyle.fonts.SEMIBOLD, 
        fontSize: 20,
        textAlign:'center', 
    },
})
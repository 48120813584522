import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Dimensions} from 'react-native';
import {dropdownStyles} from './style';
import {Entypo} from '@expo/vector-icons'
import {themeStyle} from '../../../styles'
import { ScrollView } from 'react-native-gesture-handler';
const {width, height} = Dimensions.get('window')

export const Dropdown = ({
  open,
  toggleOpen,
  children,
  disabled,
}) => {
  const [maxHeight, updateMaxHeight] = useState(height * 0.85 * 0.4)

  useEffect(() => Dimensions.addEventListener('change', (e) => {
    const { width, height } = e.window;
    updateMaxHeight(height * 0.85 * 0.4)
  }), [])

  return (
    <View style={dropdownStyles.container}>
      {open 
      ? <View style={[dropdownStyles.content, {maxHeight}]}>
          <TouchableOpacity disabled={disabled} onPress={toggleOpen} style={{position: 'absolute', top: -50, alignSelf: 'center'}}>
            <Entypo name='chevron-thin-up' size={64} color="#212121"/>
          </TouchableOpacity>
          <ScrollView style={{width: '100%'}}>
            {children}
          </ScrollView>
        </View>
      : <TouchableOpacity disabled={disabled} onPress={toggleOpen}>
          <Entypo name='chevron-thin-down' size={64} color="#212121" style={{marginTop: -50}}/>
        </TouchableOpacity>}
    </View>
  );
};

export const CurrencyDropdown = ({
  open,
  toggleOpen,
  selected,
  data,
  onSelect,
  backgroundColor,
  disabled,
}) => {
  const populateDropdown = () => {
    console.log('Hello')
      return data.map((c, index) => {
        console.log('Data', c, index)
        return (<TouchableOpacity key={index} onPress={() => onSelect(c)}>
          <Text style={[dropdownStyles.currency, {fontFamily: themeStyle.fonts.SEMIBOLD}]}>{c}</Text>
        </TouchableOpacity>)
      })
    
  }
  return (
    <View style={dropdownStyles.currencyDropdownContainer}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={[dropdownStyles.currency, {fontFamily: themeStyle.fonts.SEMIBOLD, marginRight: 7}]}>{selected}</Text>
          <TouchableOpacity onPress={toggleOpen} disabled={disabled}>
            <Entypo name={open ? 'chevron-thin-up' : 'chevron-thin-down'} size={32} color="#fff" />
          </TouchableOpacity>
        </View>
        {open && <ScrollView style={{width: '100%', height: 200, position: 'absolute', top: 75, padding: 5, backgroundColor, borderBottomRightRadius: 5, borderBottomLeftRadius: 5}}>
            {populateDropdown()}
          </ScrollView>}
    </View>
  )
}

import {StyleSheet} from 'react-native';
import {themeStyle} from '../../../styles';

const textInputStyles = StyleSheet.create({
  textInputStyle: {
    flex: 1,
    width: 342,
    color: themeStyle.colors.LIGHT_GRAY,
    fontFamily: themeStyle.fonts.SEMIBOLD,
    fontSize: themeStyle.fontSizes.FONT_SIZE_SMALL,
    padding: 0,
    margin: 0,
  },
  textInputWrapper: {
    flex: 1,
    paddingVertical: themeStyle.spacing.SPACING_15,
    paddingHorizontal: themeStyle.spacing.SPACING_15,
    backgroundColor: themeStyle.colors.WHITE,
    borderColor: themeStyle.colors.BORDER_COLOR,
    borderWidth: themeStyle.borders.WIDTH,
    borderRadius: themeStyle.borders.RADIUS_5,
    marginBottom: themeStyle.spacing.SPACING_20,
  },
  textInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textInputIcon: {
    marginLeft: themeStyle.spacing.SPACING_10,
  },
  textInputLabel: {
    backgroundColor: themeStyle.colors.WHITE,
    color: themeStyle.colors.LIGHT_GRAY,
    fontSize: themeStyle.fontSizes.FONT_SIZE_XSMALL,
    position: 'absolute',
    top: -themeStyle.spacing.SPACING_10,
    marginLeft: themeStyle.spacing.SPACING_10,
    paddingLeft: themeStyle.spacing.SPACING_5,
    paddingRight: themeStyle.spacing.SPACING_5,
  },
  textInputLabelFocused: {
    color: themeStyle.colors.PRIMARY,
  },
  textInputFocused: {
    color: themeStyle.colors.BLACK,
  },
  errorHighlight: {
    borderBottomWidth: 2,
    borderBottomColor: themeStyle.colors.RED,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 0,
  },
  errorLabel: {
    color: themeStyle.colors.RED,
  },
  focusedHighlight: {
    borderBottomWidth: 2,
    borderBottomColor: themeStyle.colors.PRIMARY,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 0,
  },
});

export default textInputStyles;

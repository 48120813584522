// Landing screen

import React from 'react';
import {View, Text, StyleSheet, Platform, TouchableOpacity, TextInput, Animated, Dimensions, Image, DeviceEventEmitter} from 'react-native';
import {Button, Dropdown, CurrencyDropdown, WaitingForClicId} from '../components'
import {commonStyle, themeStyle} from '../styles'
import {httpStatus} from '../constants'
import {accounts, recipients, currencies} from '../constants'
import ApiClient from '../api/client'
const clicIDLogin = require('../assets/images/clic-id-login.png')
const regularLogin = require('../assets/images/regular-login.png')
const {width, height} = Dimensions.get('window')
const infoHeight = height * 0.88 * 0.45

const initialState = {
    clicIdNumber: '',
    user: {},
    account: accounts && accounts[0],
    recipient: null,
    accountDropdownOpen: false,
    recipientDropdownOpen: false,
    amount: '0',
    currency: 'AED',
    polling: false,
    signingError: null,
    paymentSuccess: false,
    infoHeight,
    width,
}

export default class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
        this.animatedValue = new Animated.Value(0)
    }
    componentDidMount() {
        const id = this.props.navigation.getParam('clicId', '')
        const userData = this.props.navigation.getParam('userData', {})
        //DeviceEventEmitter.addListener('timer', this.clearTimer.bind(this));
        this.setState({clicIdNumber: id, user: userData, paymentSuccess: false})
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            this.setState({width, infoHeight: height * 0.85 * 0.45});
        })
          
    }

    populateAccountDropdown = () => {
        const {user} = this.state
        return accounts.map((acc, index) => {
            return (
            <TouchableOpacity 
                key={index} 
                style={{flexDirection: 'row', width: '100%', height: 100}}
                onPress={() => {this.updateAccount(acc)}}>
                <View style={{width: '50%', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <Text style={[commonStyle.largeText, styles.smallBlackText]}>
                        {user.name}{acc.name}
                    </Text>
                    <Text style={[commonStyle.largeText, styles.smallBlackText]}>
                        {acc.idNumber}
                    </Text>
                </View>
                <View style={{width: '50%', alignItems: 'flex-end', justifyContent: 'center'}}>
                <Text style={[commonStyle.largeText, styles.smallBlackText, {textAlign: 'right'}]}>
                        Account Balance
                </Text>
                <Text style={[commonStyle.largeText, styles.smallBlackText, {textAlign: 'right'}]}>
                    {acc.currency} {acc.balance}
                </Text>
                </View>
            </TouchableOpacity>)
        })        
    }

    updateAccount = (account) => {
        this.toggleDropdown('account')
        this.setState({account})
    }

    populateRecipientDropdown = () => {
        const {user} = this.state
        return recipients.map((recipient, index) => {
            return (
            <TouchableOpacity 
                key={index} 
                style={{flexDirection: 'row', width: '100%', height: 100}} 
                onPress={() => {this.updateRecipient(recipient)}}>
                <View style={{width: '50%', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <Text style={[commonStyle.largeText, styles.smallBlackText]}>
                        {recipient.name}
                    </Text>
                </View>
                <View style={{width: '50%', alignItems: 'flex-end', justifyContent: 'center'}}>
                <Text style={[commonStyle.largeText, styles.smallBlackText, {textAlign: 'right'}]}>
                        {recipient.idNumber}
                </Text>
                </View>
            </TouchableOpacity>)
        })        
    }

    updateRecipient = (recipient) => {
        this.toggleDropdown('recipient')
        this.setState({recipient})
    }

    updateCurrency = (currency) => {
        this.toggleDropdown('currency')
        this.setState({currency})
    }

    toggleDropdown = (type) => {
        const {accountDropdownOpen, recipientDropdownOpen, currencyDropdownOpen} = this.state
        if (type === 'account') {
            //console.log('Dropdown status: ', accountDropdownOpen)
            this.setState({accountDropdownOpen: !accountDropdownOpen})
        } else if (type === 'recipient') {
            this.setState({recipientDropdownOpen: !recipientDropdownOpen})
        } else if (type === 'currency') {
            this.setState({currencyDropdownOpen: !currencyDropdownOpen})
        } else {
            this.setState({accountDropdownOpen: false, recipientDropdownOpen: false, currencyDropdownOpen: false})
        }
    }

    signVisible = () => {
        const { amount, currency, account, recipient, user} = this.state
        return (
            amount?.length > 0 &&
            recipient
        )
    }

    signPayment = async () => {
        const {user, amount, currency, recipient, account} = this.state
        const id = this.state.clicIdNumber
        try {
            const response = await ApiClient.post(
                `/service-provider/1.0/verification`,
                {
                    body: {
                        "clicId": id,
                        "userIpAddress": "string",
                        "visibleText": `Sign Payment:\n${amount} ${currency}\nto ${recipient.name}`,
                        "invisibleText": "string",
                        "backUrl": "string",
                        "technicalReference": "string"
                    }
                }
            )
            if (response && response.status === httpStatus.OK && response.data) {
                //console.log('Succeeded creating verification for: ', id, response.data)
                const verificationId = response.data.verificationId
                this.startPolling(verificationId)
            } else {
                //console.log('Unknown error creating verification for: ', id)
            }
        } catch(e) {
            //console.log('Error creating verification for: ', id, e)
        }
    }

    startPolling = (verificationId) => {
        this.setState({polling: true, signingError: null})
        this.toggleDropdown()
        const pollingInterval = setInterval(() => poll(), 1000)
        const poll = async () => {
            console.log('Polling...')
            try {
                const response = await ApiClient.get(
                  `/service-provider/1.0/verification/${verificationId}`
                );
                if (response && response.status === httpStatus.OK && response.data) {
                    console.log('Polling', response.data)
                    const status = response.data.status
                    if (status !== 'pending') {
                        stopPolling();
                        switch (status) {
                            case 'success':
                                const data = response.data.successData
                                this.setState({paymentSuccess: true})
                                this.successAnimation()
                                //finishPayment()
                                break;
                            case 'usercancel':
                                this.setState({signingError: 'The request was canceled, try again.'})
                                break;
                            case 'timeout':
                                this.setState({signingError: 'The request timed out, try again.'})
                                break;
                            default:
                                this.setState({signingError: 'Something went wrong, try again.'})
                                break;
                        }
                    }
                }
              } catch (e) {

              }
        }
        const stopPolling = () => {
            clearInterval(pollingInterval)
            this.setState({polling: false})
        }
    }

    successAnimation = () => {
        this.animatedValue.setValue(0)
        this.timer = setTimeout(() => {
            this.finishPayment()
        }, 1700)
        Animated.timing(
          this.animatedValue,
          {
            toValue: 1,
            duration: 2000,
          }
        ).start()
    }

    finishPayment = () => {
        const {amount, currency, recipient} = this.state
        this.props.navigation.navigate('Landing', {fromLogin: false, lastPayment: {amount: amount, currency: currency, recipient: recipient?.name || ''}})
    }

    render() {
        const {
            user, 
            account, 
            recipient, 
            accountDropdownOpen, 
            recipientDropdownOpen, 
            currencyDropdownOpen, 
            amount, 
            currency, 
            polling, 
            signingError,
            paymentSuccess,
            infoHeight,
            width
         } = this.state
         const successAnimationValue = this.animatedValue.interpolate({
            inputRange: [0, 0.6, 1],
            outputRange: [0, 0, width]
          })
        const successOpacityAnimation = this.animatedValue.interpolate({
            inputRange: [0, 0.05, 1],
            outputRange: [0, 1, 1]
        })
        return (
            <View style={styles.container}>
                <View style={styles.logoContainer}>
                    <Image source={require('../assets/images/demo_bank_logo.png')} resizeMode={'contain'} style={{width: 170, height: 44, alignSelf: 'center', tintColor: '#fff'}} />
                </View>
                <View style={[styles.content, commonStyle.paddingUpDown]}>
                    <Button
                        title="Cancel"
                        onPress={() => this.props.navigation.navigate('Landing')}
                        enabled
                        style={styles.logoutButton}
                        lightTheme
                    />
                    <View style={[styles.titleContainer]}>
                        <Text style={[styles.title]}>Make a new payment</Text>
                    </View>
                    <View style={[styles.paymentDetailsContainer, commonStyle.bottom, {height: infoHeight}]}>

                        {/* FROM ACCOUNT */}
                        <View style={styles.fromAccountContainer}>
                            <View style={{width: '100%', height: infoHeight, paddingBottom: 14}}>
                                <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD}]}>
                                    From Account
                                </Text>
                                <View style={[styles.infoContainer, {marginLeft: 50}]}>
                                    <Text style={[commonStyle.mediumText, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD}]}>
                                        {account.type}
                                    </Text>
                                    <Text style={[commonStyle.mediumText, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, fontSize: 18}]}>
                                        {user.name}{account.name}
                                    </Text>
                                    <Text style={[commonStyle.mediumText,{color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, fontSize: 18}]}>
                                        {account.idNumber}
                                    </Text>
                                    <View style={{width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end', paddingHorizontal: 50}}>
                                        <Text style={[commonStyle.mediumText, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD}]}>
                                            Account Balance
                                        </Text>
                                        <Text style={[commonStyle.mediumText, {color: themeStyle.colors.BLACK_LIGHT, textAlign:'left'}]}>
                                            <Text style={{fontFamily: themeStyle.fonts.SEMIBOLD}}>{account.currency} </Text>
                                            {account.balance}
                                        </Text>
                                    </View>
                                </View>
                                <Dropdown open={accountDropdownOpen} toggleOpen={() => this.toggleDropdown('account')} disabled={polling}>
                                        {this.populateAccountDropdown()}
                                </Dropdown>
                            </View>
                        </View>


                        {/* AMOUNT */}
                        <View style={[styles.amountContainer, {borderBottomWidth: infoHeight * 0.5, borderTopWidth: infoHeight * 0.5}]}>
                            <View style={{width: '100%', height: infoHeight, paddingBottom: 14}}>
                                <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown, {color: themeStyle.colors.WHITE, fontFamily: themeStyle.fonts.SEMIBOLD, marginLeft: -50}]}>
                                    Amount
                                </Text>
                                <View style={styles.infoContainer}>
                                <TextInput
                                    style={[styles.balance, {height: 70, borderBottomColor: '#fff', borderBottomWidth: 2, width: '80%'}]}
                                    onChangeText={amount => this.setState({ amount })}
                                    numberOfLines={1}
                                    value={amount}
                                    />
                                    <CurrencyDropdown 
                                        open={currencyDropdownOpen} 
                                        selected={currency} 
                                        data={currencies}
                                        disabled={polling} 
                                        toggleOpen={() => this.toggleDropdown('currency')} 
                                        onSelect={(currency) => this.updateCurrency(currency)}
                                        backgroundColor={'#64BC78'}/>
                                </View>
                            </View>
                        </View>


                        {/* RECIPIENT */}
                        <View style={[styles.recipientContainer, {borderBottomWidth: infoHeight * 0.5, borderTopWidth: infoHeight * 0.5}]}>
                            <View style={{width: '100%', height: infoHeight, paddingBottom: 14}}>
                                <Text style={[commonStyle.mediumText, commonStyle.paddingUpDown, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, marginLeft: -50}]}>
                                    Recipient
                                </Text>
                                <View style={[styles.infoContainer]}>
                                    {recipient ? <>
                                    <Text style={[commonStyle.largeText, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD}]}>
                                        {recipient.name}
                                    </Text>
                                    <Text style={[commonStyle.mediumText, commonStyle.bottom, {color: themeStyle.colors.BLACK_LIGHT, fontFamily: themeStyle.fonts.SEMIBOLD, fontSize: 18}]}>
                                        {recipient.idNumber}
                                    </Text>
                                    </>
                                    :<Text style={[styles.selectRecipient]}>{'<Select an account>'}</Text>}
                                </View>
                                <Dropdown open={recipientDropdownOpen} toggleOpen={() => this.toggleDropdown('recipient')} disabled={polling}>
                                    {this.populateRecipientDropdown()}
                                </Dropdown>
                            </View>
                        </View>
                    </View>

                    {/* SIGN WITH CLICID */}
                    {this.signVisible() && <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        {signingError && <View>
                                <Text style={styles.signError}>{signingError}</Text>
                            </View>}
                        {polling ? 
                            <WaitingForClicId/>
                            : <Button
                                title="Sign with clicID"
                                style={{width: 342, alignSelf: 'center'}}
                                onPress={() => this.signPayment()}
                                enabled
                            />}
                   </View>}

                   {/* SUCCESS!!!! */}
                   {paymentSuccess && <Animated.View style={[styles.successContent, {opacity: successOpacityAnimation}]}>
                        <View style={[styles.paymentDetailsContainer, {backgroundColor: '#FFF', height: infoHeight}]}>
                            <Animated.View 
                                style={[styles.paymentDetailsContainer, {marginLeft: successAnimationValue}]}>
                                <View style={styles.fromAccountContainer}>
                                </View>
                                <View style={[styles.amountContainer, {borderBottomWidth: infoHeight * 0.5, borderTopWidth: infoHeight * 0.5}]}>
                                    <View style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={[styles.balance]}>Success!</Text>
                                    </View>
                                </View>
                                <View style={[styles.recipientContainer, {borderBottomWidth: infoHeight * 0.5, borderTopWidth: infoHeight * 0.5}]}>
                                </View>
                            </Animated.View>
                        </View>
                    </Animated.View>}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: themeStyle.colors.NAVY,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logoContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60,
        padding: 10,
    },
    content: {
        height: '85%',
        width: '100%',
        backgroundColor: '#f4f4f4',        
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    successContent: {
        width: '100%',
        height: '100%', 
        position: "absolute", 
        top: 0, 
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f4f4f4',
    },
    logoutButton: {
        position: 'absolute',
        top: 7,
        right: 15,
        width: 150,
    },
    titleContainer: {
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: themeStyle.fonts.BOLD,
        fontSize: 40,
        color: themeStyle.colors.BLACK_LIGHT,
    },
    paymentDetailsContainer: {
        flexDirection: 'row',
        width: '100%',
        height: infoHeight,
        alignSelf: 'center',
    },
    fromAccountContainer: {
        width:'31%',
        height: '100%',
        backgroundColor: '#FFF',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 30,
    },
    amountContainer: {
        width: '33%',
        height: '100%',
        backgroundColor: '#64BC78',
        alignItems: 'flex-start',
        justifyContent: 'center',

        paddingRight: 20,
        paddingLeft: 50,
        borderStyle: 'solid',
        borderTopWidth: infoHeight * 0.5,
        borderRightWidth: 0,
        borderBottomWidth: infoHeight * 0.5,
        borderLeftWidth: 50,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: '#fff',
    },
    recipientContainer: {
        width: '36%',
        height: '100%',
        backgroundColor: '#FFF',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 50,
        paddingRight: 20,
        borderStyle: 'solid',
        borderTopWidth: infoHeight * 0.5,
        borderRightWidth: 0,
        borderBottomWidth: infoHeight * 0.5,
        borderLeftWidth: 90,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: '#64BC78',
    },
    balance: {
        fontFamily: themeStyle.fonts.BOLD,
        fontSize: 40,
        color: themeStyle.colors.WHITE,
    },
    selectRecipient: {
        fontFamily: themeStyle.fonts.REGULAR,
        fontSize: 36,
        color: themeStyle.colors.LIGHT_GRAY_50,
    },
    signError: {
        fontFamily: themeStyle.fonts.SEMIBOLD,
        fontSize: 16,
        color: themeStyle.colors.RED,
        alignSelf: 'center',
        textAlign: 'center',
    },
    actionButtonsContainer: {
        flexDirection: 'row',
        height: 50,
        marginTop: 50,
        width: '80%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionButton: {
        height: '100%', 
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        borderRadius: 5,
        marginHorizontal: 50,
    },
    actionButtonText: {
        color: themeStyle.colors.WHITE, 
        fontFamily: themeStyle.fonts.SEMIBOLD, 
        fontSize: 20,
        textAlign:'center', 
    },
    infoContainer: {
        height: '80%',
        width: '100%',
        justifyContent: 'center',
        paddingBottom: 20,
    },
    smallBlackText: {
        color: themeStyle.colors.BLACK_LIGHT, 
        fontFamily: themeStyle.fonts.SEMIBOLD, 
        fontSize: 18
    },
    inputContainer: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    loginMethods: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    loginMethod: {
        width: 55,
        height: 55,
        marginHorizontal: 35,
        alignItems: 'center',
        justifyContent: 'center',
    }
})
const themeStyle = {
  fontSizes: {
    FONT_SIZE_XXSMALL: 10,
    FONT_SIZE_XSMALL: 12,
    FONT_SIZE_SMALL: 14,
    FONT_SIZE_MEDIUM: 16,
    FONT_SIZE_LARGE: 24,
    FONT_SIZE_XLARGE: 32,
  },
  fontWeight: {
    BOLD: '600',
    WEIGHT_100: '100',
  },
  spacing: {
    SPACING_5: 5,
    SPACING_10: 10,
    SPACING_15: 15,
    SPACING_20: 20,
    SPACING_25: 25,
  },
  borders: {
    WIDTH: 1,
    RADIUS_3: 3,
    RADIUS_5: 5,
    RADIUS_7: 7.5,
    RADIUS_10: 10,
    RADIUS_20: 20,
    RADIUS_30: 30,
    RADIUS_50: 50,
  },
  colors: {
    PRIMARY: '#1B3077',
    PRIMARY_LIGHT: '#C0CBEE',
    PRIMARY_50: '#00748380',
    PRIMARY_64: '#007483A3',
    PRIMARY_15: '#00748326',
    NAVY: "#1B3077",
    BLACK: '#000',
    BLACK_LIGHT: '#212121',
    WHITE: '#FFF',
    WHITE_50: '#FFFFFF80',
    WHITE_80: '#FFFFFFE6',
    GRAY40: '#666666',
    DARK_GRAY: '#333',
    DARK_GRAY_50: '#33333380',
    MEDIUM_GRAY: '#585858',
    MEDIUM_LIGHT_GRAY: '#9DA1A4',
    LIGHT_GRAY: '#9DA1A4',
    LIGHT_GRAY_50: '#9DA1A480',
    WHITE_SMOKE: '#F5F6F8',
    RED: '#A70E0E',
    BORDER_COLOR: '#D8DEDF',
    GREEN: '#39AB75',
    MEADOW: '#11B683',
    PIN_BOX_COLOR: '#D6DEE0',
    TABS: {
      BASE: '#EEEEEE',
      PENDING: '#979797',
      ACCEPTED: '#3DA4AB',
      DECLINED: '#FE8A71',
    },
  },
  fonts: {
    REGULAR: 'NunitoSans-Regular',
    SEMIBOLD: 'NunitoSans-SemiBold',
    LIGHT: 'NunitoSans-Light',
    BOLD: 'NunitoSans-Bold',
  },
};

export default themeStyle;
